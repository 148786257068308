import {BreadcrumbOptionsModel} from '@web/core/models/local/breadcrumb.model'

export class UpdateBreadcrumbs {
  static readonly type = '[Breadcrumbs] UpdateBreadcrumbs'

  constructor(public options: BreadcrumbOptionsModel) {
  }
}

export class HideBreadcrumbs {
  static readonly type = '[Breadcrumbs] HideBreadcrumbs'
}
